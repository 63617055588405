/* eslint @typescript-eslint/no-unused-vars: ["error", { "varsIgnorePattern": "openExternalLinksInNewTab|buildEmailLinks|assemblePdf" }] */

function openExternalLinksInNewTab(){ // open href's to external website in new window
  const domain = document.location.protocol + '//' + document.location.hostname;
  $.expr[':'].external = a => a.href.indexOf('http') === 0 && a.href.indexOf(domain) != 0;
  $('a:external').each(function() {
    $(this).attr('target', '_blank');
  });
}

function buildEmailLinks(selector){ // build email links (spam prevention)
  $(selector).each(function(){
    const data = $(this).data();
    $(this).attr('href', `mailto:${data.pre}@${data.after}`);
    if (data.textPre) $(this).text(data.textPre+'@'+data.textAfter);
  });
}

const clipToEdgeOnOverflow = { // remove right margin for overflowing text elements
  apply(selector) {
    this.elements = this.elements || $(selector);
    this.elements.each(function(){
      if ($(this).data('overflowBreakpoint') < $(this).innerWidth()) {// if already set and no longer overflowing
        clipToEdgeOnOverflow.undo();
      }
      else if (!$(this).data('overflowBreakpoint') && this.scrollWidth > $(this).innerWidth()) { // if not already set and overflowing
        const pxToEdge = $(this).data('pxToEdge') || {
          left: $(this).offset().left,
          right: window.innerWidth - ($(this).offset().left + $(this).width()),
          get total() {
            return this.left + this.right;
          }
        };

        $(this)
          .data('pxToEdge', pxToEdge)
          .data('overflowBreakpoint', this.scrollWidth + pxToEdge.total)
          .css({
            'max-width': `calc(100% + ${pxToEdge.total}px)`,
            'width': `calc(100% + ${pxToEdge.total}px)`,
            'margin-left': -pxToEdge.left,
            'margin-right': -pxToEdge.right,
            'padding-left': pxToEdge.left
          })
          .children().css({'border-right': pxToEdge.right + 'px solid transparent'})
        ;
      }
    });
  },
  undo() {
    this.elements.removeData('overflowBreakpoint').removeAttr('style');
  }
};

const tooltips = {
  init() {
    $('[data-tooltip][data-tooltip!=""]').on('mouseenter', function(){
      const el = $(this);
      const tt = $(`<div id="tdi--tooltip">${el.data('tooltip')}</div>`);

      tt.css('opacity', 0).appendTo('body');

      tooltips.setPosition(el, tt);
      $(window).resize(function(){ tooltips.setPosition(el, tt); });

      el.on('mouseleave', function(){ tooltips.remove(tt); });
      tt.on('click', function(){ tooltips.remove(tt); });
    });
  },
  setPosition(el, tt) {
    tt.css('max-width', $(window).width() < tt.outerWidth() * 1.5 ? $(window).width() / 2 : 400);
    tt.removeClass();

    let posLeft = el.offset().left + (el.outerWidth() / 2) - (tt.outerWidth() / 2);
    let posTop  = el.offset().top - tt.outerHeight() - 20;

    if (posLeft < 0) {
      posLeft = el.offset().left + el.outerWidth() / 2 - 20;
      tt.addClass('left');
    }
    else if (posLeft + tt.outerWidth() > $(window).width()) {
      posLeft = el.offset().left - tt.outerWidth() + el.outerWidth() / 2 + 20;
      tt.addClass('right');
    }
    if (posTop < 0) {
      posTop = el.offset().top + el.outerHeight();
      tt.addClass('top');
    }

    tt.css({left: posLeft, top: posTop}).animate({top: '+=10', opacity: 1}, 50);
  },
  remove(tt) {
    tt.animate({top: '-=10', opacity: 0}, 50, function(){ tt.remove(); });
  }
};

const assemblePdf = { // not tested, contact proddev before use
  cookieName: 'assemblePdf',
  get cookie() { // returns cookie contents, first time retrieved from cookie and stored in obj prop, then retrieved from obj prop
    if (!$.cookie) throw new Error(`Jquery Cookie plugin is required for use of [assemblePdf]!`);
    this.cookieTemp = this.cookieTemp || $.cookie(this.cookieName) ? $.cookie(this.cookieName).split(';') : [];
    return this.cookieTemp;
  },
  mutateCookie(nodeInfo, action) { // note: make sure this.cookieTemp exists
    if      (action=='ins' && this.cookie)  this.cookieTemp.push(nodeInfo);
    else if (action=='del')                 this.cookieTemp = this.cookie.filter(item => item != nodeInfo);
    $.cookie(this.cookieName, this.cookie.join(';'), {expires: 7, path: '/'});
  },
  add(nodeInfo) {
    this.mutateCookie(nodeInfo, 'ins');
  },
  remove(nodeInfo) {
    this.mutateCookie(nodeInfo, 'del');
  },
  isAdded(nodeInfo) {
    return this.cookie.includes(nodeInfo);
  },
  nodeObj(nodeInfo) {
    const arr = nodeInfo.split('|');
    return {index: arr[0], id: arr[1], title: arr[2], url: arr[3]};
  },
  get count() {
    return this.cookie.length;
  },
  get nodes() {
    return this.cookie.sort().map(nodeInfo => this.nodeObj(nodeInfo));
  },
  get urlParamNodes() {
    return '&nodes=' + this.nodes.map(node => node.id).join('-');
  }
};
